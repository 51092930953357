import React from 'react';
import { StaticQuery, graphql } from "gatsby";

import styles from './styles.module.scss';

interface Props {
  category: string;
}

interface Document {
  frontmatter: {
    title: string;
    description: string;
    file: string;
    category: string;
  }
}

interface DocumentBlock {
  title: string;
  description: string;
  file: string;
  category: string;
}

const Inner = ({ category }: Props) => {
  return (
    <div className={styles.inner}>
      <StaticQuery
        query={
          graphql`query DocumentQuery {
            allMarkdownRemark (
              filter: {
                fileAbsolutePath: {regex : "\/documents/"},
              }
            ) {
              nodes {
                frontmatter {
                  title
                  description
                  category
                  file
                  content
                }
              }
            }
          }`
        }
        render={data => {
          const documents = data.allMarkdownRemark.nodes.map((doc: Document) => (
            {
              title: doc.frontmatter.title,
              description: doc.frontmatter.description,
              file: doc.frontmatter.file,
              category: doc.frontmatter.category
            }
          )).filter((doc: DocumentBlock) => doc.category === category);

          return (
            <div>
              {documents && documents.map((doc: DocumentBlock) => (
                <div className={styles.fileblock}>
                  <a href={doc.file} target="_blank">{doc.title}</a>
                  <p>{doc.description}</p>
                </div>
              ))}
            </div>
        )}}
      />
    </div>
  )
}

export default Inner;
