import React, { useState } from 'react';
import { uniq } from 'lodash';
import { SEO, Layout } from "components";
import AnimateHeight from 'react-animate-height';
import Arrow from 'images/arrow.svg';
import { StaticQuery, graphql } from "gatsby";

import Inner from './Inner';

import styles from './styles.module.scss';

interface DocumentBlock {
  title: string;
  description: string;
  file: string;
  category: string;
}

interface Document {
  frontmatter: {
    title: string;
    description: string;
    file: string;
    category: string;
  }
}

const Parents = () => {
  const isActive = (state: boolean) => state ? 'auto' : 0

  return (
    <Layout>
      <SEO />
      <div className={styles.parents}>
        <div className={styles.container}>
          <h3>Parent Documets</h3>
          <StaticQuery
          query={
            graphql`query DocumentIndexQuery {
              allMarkdownRemark (
                filter: {
                  fileAbsolutePath: {regex : "\/documents/"},
                }
              ) {
                nodes {
                  frontmatter {
                    category
                  }
                }
              }
            }`
          }
          render={data => {
            const documents = data.allMarkdownRemark.nodes.map((doc: Document) => (
              {
                category: doc.frontmatter.category
              }
            )).map((doc: DocumentBlock) => doc.category);
              return (
                uniq(documents).map(d => (
                  <div className={styles.block}>
                  <button onClick={() => {}}>
                    {d}
                    <img src={Arrow} />
                  </button>
                  <AnimateHeight height={isActive(documents)} className={styles.fullWidth}>
                    <Inner category="documents" />
                  </AnimateHeight>
                  </div>
                ))
              )}}
            />
        </div>
      </div>
    </Layout>
  )
}

export default Parents;
